<template>
  <div>
    <div class="view-body">
      <div class="gq-news-body">
        <!-- <gqswiper /> -->
        <div class="gq-news-title-box">
          <div class="gq-news-title">Industry Updates</div>
          <div class="gq-news-title-detail">
            Learn the most up-to-date industry updates <br />
            Fast access to famous universities offers
          </div>
        </div>
        <div class="gq-news-list-box">
          <card
            class="gq-news-item"
            @trueShow="trueShow(item)"
            v-for="(item, i) in inforList"
            :key="i"
            :inforData="item"
          />
        </div>
        <div class="gq-news-page-box">
          <el-pagination
            background
            layout="prev, pager, next"
            @prev-click="prev"
            @next-click="next"
            @current-change="current"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="gq-news-phone-list-box">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <card
              class="gq-news-item"
              v-for="(item, i) in inforList"
              :key="i"
              :inforData="item"
            />
          </van-list>
        </div>
        <div class="gq-news-top" id="gq-news-top" @click="top">
          <div class="gq-news-top-img-box">
            <img src="@/assets/logos/top.png" alt="" />
            TOP
          </div>
        </div>

        <detail v-show="detailShow" ref="refdetail" @changeShow="falseShow" />
      </div>
    </div>
    <div class="gq-bottom-box">
      <gqIntroduction />
    </div>
  </div>
</template>
<script>
// import gqswiper from "@/components/gq-swiper.vue";
import card from "./components/card.vue";
import gqIntroduction from "@/components/gq-introduction.vue";
import { List } from "vant";
import detail from "@/components/detail.vue";
import { iesInfoList, iesInfoGetById } from "@/api";
export default {
  components: {
    // gqswiper,
    card,
    gqIntroduction,
    detail,
    vanList: List,
  },
  data() {
    return {
      inforList: [],
      loading: false,
      finished: false,
      detailShow: false,
      page: 0,
      total: 0,
      detailData: {},
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },

    prev(e) {
      this.page = e;
      this.onLoad();
    },
    current(e) {
      this.page = e;
      this.onLoad();
    },
    next(e) {
      this.page = e;
      this.onLoad();
    },
    falseShow() {
      this.detailShow = false;
    },
    trueShow(item) {
      this.$refs.refdetail.setData(item);
      this.detailShow = true;
    },
    onLoad() {
      iesInfoList({ rows: 12, page: ++this.page }).then((res) => {
        this.inforList = res.rows;
        this.total = Number(res.total);
        this.loading = false;
        if (this.inforList.length >= this.total) {
          this.finished = true;
        }
      });
    },
  },
  mounted() {
    this.onLoad();
    if (window.webType()) {
      window.addEventListener("scroll", function () {
        let dom = document.getElementById("gq-news-top");
        if (dom) {
          if (window.scrollY >= 600) {
            dom.style.display = "flex";
          } else {
            dom.style.display = "none";
          }
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.gq-news-title-box {
  margin-top: 60px;
  text-align: center;
  .gq-news-title {
    font-size: 54px;
    font-weight: bold;
    color: #222222;
  }
  .gq-news-title-detail {
    margin: 0 auto;
    font-size: 15px;
    padding-top: 20px;
    font-weight: 300;
    color: #222222;
    width: 338px;
    // border-bottom: 2px solid #648693;
    padding-bottom: 50px;
  }
}
.gq-news-list-box {
  display: flex;
  // justify-content: center;
  width: 1070px;
  overflow-x: hidden;
  margin: 0 auto;
  flex-wrap: wrap;
  .gq-news-item {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.gq-news-phone-list-box {
  display: none;
}
.gq-news-top {
  display: none;
}
.gq-news-page-box {
  display: flex;
  justify-content: center;
  margin-top: 46px;
  margin-bottom: 67px;
}
/deep/ .btn-prev {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .btn-next {
  width: 68px;
  height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
/deep/ .number {
  width: 38px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background: #ffffff;
}
/deep/ .active {
  background: linear-gradient(225deg, #62818e 0%, #81ccd9 100%);
}
@media only screen and (max-width: 768px) {
  .gq-news-page-box {
    display: none;
  }
  .gq-bottom-box {
    display: none;
  }
  .gq-news-title-box {
    width: 10rem;
    margin-top: 0.8rem;
    text-align: center;
    .gq-news-title {
      font-size: 0.8rem;
      font-weight: bold;
      color: #222222;
    }
    .gq-news-title-detail {
      font-size: 0.37rem;
      padding-top: 0.11rem;
      font-weight: 300;
      color: #222222;
      width: 10rem;
      padding-bottom: 0px;
      border-bottom: 0rem;
    }
  }
  .gq-news-list-box {
    display: none;

    // margin-top: 0.99rem;
  }
  .gq-news-phone-list-box {
    display: block;
    padding-left: 0.5rem;
    width: 9.44rem;
    justify-content: space-between;
    flex-direction: column;
    overflow-x: hidden;

    .gq-news-item {
      margin-top: 0.4rem;
    }
  }
  .gq-news-top {
    display: none;
    justify-content: center;
    // flex-direction: column;
    width: 1.33rem;
    height: 1.33rem;
    border-radius: 50%;
    background: #6f9fac;
    text-align: center;
    position: fixed;
    bottom: 0.53rem;
    right: 0.53rem;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.16);
    font-size: 0.24rem;
    font-weight: 400;
    color: #ffffff;
    .gq-news-top-img-box {
      margin-top: 0.2rem;
      width: 0.54rem;
      height: 0.54rem;

      img {
        width: 0.54rem;
        height: 0.54rem;
      }
    }
  }
}
</style>